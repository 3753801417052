import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
  return (
    <footer data-testid='footer-element'>
      <ul>
        <li>
          <a href='https://facebook.com'>Facebook</a>
        </li>
        <li>
          <a href='https://github.com'>GitHub</a>
        </li>
        <li>
          <a href='https://twitter.com'>Twitter</a>
        </li>
      </ul>
      <Link to='/impressum' className='impressum-link'>
        Impressum
      </Link>{' '}
      {/* Link mit CSS-Klasse */}
    </footer>
  )
}

export default Footer
