import React from 'react'
import './Spinner.css' // Optional: Für Styles

const Spinner = () => (
  <div className='spinner'>
    <div className='loader'></div>
  </div>
)

export default Spinner
