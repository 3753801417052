/* eslint-disable no-undef */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { OidcProvider } from '@axa-fr/react-oidc'

// Prüfe, ob der Browser Safari ist
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

// Basis-URL für den Authority-Server
const baseAuthorityUrl = `${window.location.protocol}//${process.env.REACT_APP_KEYCLOAD_URL_PREFIX}${window.location.hostname}${process.env.REACT_APP_KEYCLOAD_PORT}`
const baseRealmUrl = `${baseAuthorityUrl}/realms/keycloak-react-auth`

const oidcConfiguration = {
  client_id: 'my-react-app',
  redirect_uri: `${window.location.origin}/authentication/callback`,
  silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
  scope: 'openid profile email', // 'offline_access' könnte für Refresh-Token hinzugefügt werden
  authority: baseAuthorityUrl,
  ...(isSafari ? {} : { service_worker_relative_url: '/OidcServiceWorker.js' }),
  service_worker_only: false,
  authority_configuration: {
    issuer: baseRealmUrl,
    authorization_endpoint: `${baseRealmUrl}/protocol/openid-connect/auth`,
    token_endpoint: `${baseRealmUrl}/protocol/openid-connect/token`,
    introspection_endpoint: `${baseRealmUrl}/protocol/openid-connect/token/introspect`,
    userinfo_endpoint: `${baseRealmUrl}/protocol/openid-connect/userinfo`,
    end_session_endpoint: `${baseRealmUrl}/protocol/openid-connect/logout`,
    jwks_uri: `${baseRealmUrl}/protocol/openid-connect/certs`,
    check_session_iframe: `${baseRealmUrl}/protocol/openid-connect/login-status-iframe.html`,
    frontchannel_logout_session_supported: true,
    frontchannel_logout_supported: true,
  },
}

const root = ReactDOM.createRoot(document.getElementById('root'))

console.log('config OIDC')
console.log(oidcConfiguration)

root.render(
  <React.StrictMode>
    <OidcProvider configuration={oidcConfiguration}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </OidcProvider>
  </React.StrictMode>,
)

// Optionale Funktionalität: Performance-Messung aktivieren
reportWebVitals()
