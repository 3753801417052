import { Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import LandingPage from './components/LandingPage'
import ProjectsPage from './components/ProjectsPage'
import BlogPage from './components/BlogPage'
import SecuredBlogAdmin from './components/SecuredBlogAdmin'
import './App.css'
import Impressum from './components/Impressum'

function App() {
  return (
    // <Router> Ausgelagert in die index.js, da für Tests Überschrieben
    <div className='App'>
      <Navbar />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/projects' element={<ProjectsPage />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/impressum' element={<Impressum />} />
        <Route path='/blogadmin' element={<SecuredBlogAdmin />} />
        <Route path='*' element={<LandingPage />} />
      </Routes>
      <Footer />
    </div>
    // </Router>
  )
}
export default App
