import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types' // Importiere PropTypes
import {
  fetchPosts,
  createNewPost,
  updatePostById,
  deletePostById,
} from '../services/postsApi'
import './BlogAdmin.css'

function BlogManager({ accessToken }) {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [formData, setFormData] = useState({ title: '', contents: '' })

  useEffect(() => {
    async function loadArticles() {
      try {
        const posts = await fetchPosts(accessToken)
        console.log('Access Token:', accessToken)
        setArticles(posts)
      } catch (err) {
        setError(
          err.message ||
            'Fehler beim Laden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut',
        )
      } finally {
        setLoading(false)
      }
    }

    loadArticles()
  }, [accessToken])

  const handleCreate = async () => {
    try {
      const newPost = await createNewPost(accessToken, formData)
      setArticles([...articles, newPost])
      setFormData({ title: '', contents: '' })
    } catch (err) {
      alert('Error creating post: ' + err.message)
    }
  }

  const handleUpdate = async (id) => {
    try {
      const updatedPost = await updatePostById(accessToken, id, formData)
      setArticles(
        articles.map((article) => (article._id === id ? updatedPost : article)),
      )
      setSelectedArticle(null)
      setFormData({ title: '', contents: '' })
    } catch (err) {
      alert('Error updating post: ' + err.message)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deletePostById(accessToken, id)
      setArticles(articles.filter((article) => article._id !== id))
    } catch (err) {
      alert('Error deleting post: ' + err.message)
    }
  }

  const handleEdit = (article) => {
    setSelectedArticle(article)
    setFormData({ title: article.title, contents: article.contents })
  }

  if (loading) {
    return <div className='loading'>Loading blog posts...</div>
  }

  if (error) {
    return <div className='error'>Error: {error}</div>
  }

  return (
    <div className='blog-manager'>
      <h1>Blog Verwaltung</h1>
      <div className='form'>
        <input
          type='text'
          placeholder='Title'
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        />
        <textarea
          placeholder='Contents'
          value={formData.contents}
          onChange={(e) =>
            setFormData({ ...formData, contents: e.target.value })
          }
        ></textarea>
        {selectedArticle ? (
          <button onClick={() => handleUpdate(selectedArticle._id)}>
            Update Post
          </button>
        ) : (
          <button onClick={handleCreate}>Create Post</button>
        )}
      </div>
      {articles.length === 0 ? (
        <p>No blog posts available.</p>
      ) : (
        articles.map((article) => (
          <div key={article._id} className='blog-post'>
            <h3>ID {article._id}</h3>
            <h2>{article.title}</h2>
            <p>{article.contents}</p>
            <button onClick={() => handleEdit(article)}>Edit</button>
            <button onClick={() => handleDelete(article._id)}>Delete</button>
          </div>
        ))
      )}
    </div>
  )
}

// Definiere Prop-Typen für die Komponente
BlogManager.propTypes = {
  accessToken: PropTypes.string.isRequired, // accessToken muss ein String sein und wird benötigt
}

export default BlogManager
