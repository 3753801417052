import React, { useEffect } from 'react'
import Spinner from './Spinner' // Spinner importieren

const PageError = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }, [])

  return (
    <div className='w-screen h-screen flex items-center justify-center flex-col'>
      <Spinner />
    </div>
  )
}

export default PageError // Default Export
