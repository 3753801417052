import React from 'react';
import './Impressum.css'; // Importiere das Stylesheet

function Impressum() {
  return (
    <div className="impressum-container">
      <h1>Impressum</h1>
      <p className="contact-info">
        <strong>Kontakt:</strong><br />
        Telefon: +49 123 456789<br />
        E-Mail: info@mustermann.de
      </p>
      <p>
        <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br />
        Max Mustermann<br />
        Musterstraße 123<br />
        23456 Musterstadt
      </p>
      <p>
        <strong>Haftungsausschluss:</strong><br />
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
        Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>

      <h2>Datenschutzerklärung</h2>
      <p>
        <strong>Datenschutz:</strong><br />
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten
        daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TMG). In diesen
        Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der
        Datenverarbeitung im Rahmen unserer Website.

        Wenn Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks
        Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert.
        Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.

        Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
        Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung
        Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
        sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren.
      </p>
    </div>
  );
}

export default Impressum;
