import React, { useState, useEffect } from 'react'
import { fetchPosts } from '../services/postsApi'
import './BlogPage.css'

function BlogPage() {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const formatDate = (dateString) => {
    const date = new Date(dateString)

    // Separates Datum und Uhrzeit formatieren
    const datePart = new Intl.DateTimeFormat('de-DE', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(date)

    const timePart = new Intl.DateTimeFormat('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(date)

    // Datum und Uhrzeit zusammenfügen
    return `${datePart}, ${timePart} Uhr`
  }

  useEffect(() => {
    async function loadArticles() {
      try {
        const posts = await fetchPosts()
        setArticles(posts)
      } catch (err) {
        setError(
          err.message ||
            'Fehler beim laden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut',
        )
      } finally {
        setLoading(false)
      }
    }

    loadArticles()
  }, [])

  if (loading) {
    return <div className='loading'>Loading blog posts...</div>
  }

  if (error) {
    return (
      <div className='error'>
        Error: {error}
        <h1>Blog</h1>
      </div>
    )
  }

  return (
    <div className='blog-page'>
      <h1>Blog</h1>
      {articles.length === 0 ? (
        <p>No blog posts available.</p>
      ) : (
        articles.map((article, index) => (
          <div key={article._id || index} className='blog-post'>
            <h3>{formatDate(article.updatedAt)}</h3>
            <h2>{article.title}</h2>
            <p>{article.contents}</p>
          </div>
        ))
      )}
    </div>
  )
}

export default BlogPage
