import React, { useState, useEffect } from 'react'
import BlogManage from './BlogAdmin'
import { OidcSecure, useOidc, useOidcAccessToken } from '@axa-fr/react-oidc'
import './SecuredBlogAdmin.css'
import PageError from './PageError'

const SecuredBlogAdmin = () => {
  const { login, logout, isAuthenticated } = useOidc()
  const { accessToken } = useOidcAccessToken()
  const [error, setError] = useState(false)

  const handleLogin = () => {
    try {
      login()
    } catch (err) {
      console.error('Login-Fehler:', err)
      setError(true)
    }
  }

  const handleLogout = () => {
    try {
      logout()
    } catch (err) {
      console.error('Logout-Fehler:', err)
      setError(true)
    }
  }

  useEffect(() => {
    if (!isAuthenticated && accessToken === null) {
      setError(false) // Kein Fehlerzustand bei normalem Login-Flow
    }
  }, [isAuthenticated, accessToken])

  if (error) {
    return <PageError />
  }

  return (
    <div className='secured-blog-admin'>
      {!isAuthenticated ? (
        <button onClick={handleLogin}>Login</button>
      ) : (
        <OidcSecure>
          <button onClick={handleLogout}>Logout</button>
          <div className='blog-manager'>
            <BlogManage accessToken={accessToken} />
          </div>
        </OidcSecure>
      )}
    </div>
  )
}

export default SecuredBlogAdmin
