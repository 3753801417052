// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_API_BASE_URL

export async function fetchPosts(
  token,
  sortBy = 'createdAt',
  sortOrder = 'desc',
) {
  console.log('Access Token:', token)
  const response = await fetch(
    `${BASE_URL}api/v1/posts?sortBy=${sortBy}&sortOrder=${sortOrder}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Token im Authorization-Header
      },
    },
  )
  if (!response.ok) throw new Error('Error fetching posts')
  return response.json()
}

export async function fetchPostById(token, id) {
  const response = await fetch(`${BASE_URL}api/v1/posts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Token im Authorization-Header
    },
  })
  if (!response.ok) throw new Error('Error fetching post')
  return response.json()
}

export async function createNewPost(token, postData) {
  const response = await fetch(`${BASE_URL}api/v1/posts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Token im Authorization-Header
    },
    body: JSON.stringify(postData),
  })
  if (!response.ok) throw new Error('Error creating post')
  return response.json()
}

export async function updatePostById(token, id, updatedData) {
  const response = await fetch(`${BASE_URL}api/v1/posts/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Token im Authorization-Header
    },
    body: JSON.stringify(updatedData),
  })
  if (!response.ok) throw new Error('Error updating post')
  return response.json()
}

export async function deletePostById(token, id) {
  const response = await fetch(`${BASE_URL}api/v1/posts/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`, // Token im Authorization-Header
    },
  })
  if (!response.ok) throw new Error('Error deleting post')
}
